
    import Vue from "vue";
    import Component from "vue-class-component";
    import SignInForm from "../components/SignInForm.vue";
    import { goToIntendedUrl } from "../router/router";

    // https://vuejs.org/v2/guide/typescript.html

    @Component({
        components: { SignInForm }
    })
    export default class SignIn extends Vue {

        onAuthenticated() {
            goToIntendedUrl();
        }

    }
